import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const VoteHeader = (props) => {
  return (
    <Card className="card-not-active" style={{ marginBottom: "20px" }}>
      <Card.Body>
        <Row>
          <Col>
            <h1>Abstimmung Farbdesign</h1>
            <p>
              Liebe Kolleginnen und Kollegen im stadtbremischen Rettungsdienst,
            </p>
            <p>
              Im Zusammenhang mit der Beschaffung der neuen persönlichen
              Schutzausrüstung für die Mitarbeitenden des stadtbremischen
              Rettungsdienst hat sich eine Projektgruppe, bestehend aus
              Mitarbeitenden aller am Rettungsdienst beteiligten Institutionen
              (ASB, BF, DRK, MHD, ÄLRD, LNÄ) verschiedene Ideen zur
              Funktionsfähigkeit gesammelt. Im nächsten Schritt soll nun die
              Farbkombination der neuen PSA bestimmt werden. Hierzu hat die
              Projektgruppe drei Vorschläge ausgearbeitet, die nun mittels einer
              Abstimmung von allen Mitarbeitenden des stadtbremischen
              Rettungsdienstes ausgewählt werden soll.{" "}
            </p>
            <p>
              <strong>
                Die Abstimmung ist beendet! Vielen Dank für die Unterstützung.
              </strong>
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default VoteHeader;
