import React from "react";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";

const VoteOption = (props) => {
  let progress = 0;
  if (props.voted) {
    if (props.resultTotal > 0) {
      progress = (props.result / props.resultTotal) * 100;
    }
  }
  return (
    <Card className={props.active ? "card-active" : "card-not-active"}>
      <Card.Header>{props.title}</Card.Header>
      <Card.Img
        variant="top"
        src={props.bild1}
        style={{ paddingBottom: "0px" }}
      />
      <Card.Img variant="top" src={props.bild2} />
      <Card.Body>
        {/* <Card.Title>Merkmale</Card.Title>
        <Card.Text>
          {props.text}
        </Card.Text> */}
      </Card.Body>
      {!props.voted ? (
        <Card.Footer className="text-muted" style={{ paddingTop: "20px" }}>
          <Row>
            <Col className="text-center">
              <Form.Label>Ich stimme für dieses Design</Form.Label>
            </Col>
          </Row>

          <Row style={{ marginTop: "15px" }}>
            <Col xs={8}>
              <Form.Group className="mb-3" controlId="formBasicText">
                <Form.Control
                  type="text"
                  placeholder="Code eingeben"
                  value={props.code}
                  onChange={props.changeCode}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Button
                variant="primary"
                type="submit"
                disabled={props.code.length === 8 ? false : true}
                onClick={() => {
                  props.makeVote(props.design);
                }}
              >
                Abstimmen
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      ) : // (
      //   <Card.Footer className="text-muted" style={{ paddingTop: "20px" }}>
      //     <Row>
      //       <Col className="text-center">
      //         <p>Aktueller Stand Design {props.design}</p>
      //         <ProgressBar
      //           animated
      //           now={progress}
      //           label={`${progress.toFixed(2)} %`}
      //           style={{
      //             marginTop: "15px",
      //             marginBottom: "15px",
      //             height: "35px",
      //             fontSize: "14px",
      //             fontWeight: "bold",
      //           }}
      //         />
      //       </Col>
      //     </Row>
      //   </Card.Footer>
      // )
      undefined}
    </Card>
  );
};

export default VoteOption;
