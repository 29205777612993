import React from "react";
import axios from "axios";

import VoteOption from "./VoteOption";
import VoteHeader from "./VoteHeader";
// import VoteForm from './VoteForm';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//import Form from 'react-bootstrap/Form';

class Vote extends React.Component {
  constructor() {
    super();
    this.state = {
      vote: 0,
      voted: true,
      code: "",
      voteResult: {
        design1: 0,
        design2: 0,
        design3: 0,
        design4: 0,
        total: 0,
      },
    };
  }

  changeVote = (vote) => {
    this.setState((state, props) => ({
      ...state,
      vote,
    }));
  };

  changeCode = (e) => {
    this.setState((state, props) => ({
      ...state,
      code: e.target.value,
    }));
  };

  makeVote = (vote) => {
    let params = new URLSearchParams();
    params.append("code", this.state.code);
    params.append("vote", vote);
    axios
      .post("https://vote.moinmoin-media.de/api/update_codes.php", params)
      .then((response) => {
        console.log(this.state);
        console.log(response.data);
        this.setState((state, props) => ({
          ...state,
          voted: true,
        }));
        this.getResults();
      });
  };

  getResults = () => {
    axios
      .get("https://vote.moinmoin-media.de/api/get_codes.php?method=votes")
      .then((response) => {
        console.log(response.data);
        let design1 = 0;
        let design2 = 0;
        let design3 = 0;
        let design4 = 0;
        if (response.data.result[0]) {
          if (response.data.result[0].vote === "1")
            design1 = parseInt(response.data.result[0].count);
          if (response.data.result[0].vote === "2")
            design2 = parseInt(response.data.result[0].count);
          if (response.data.result[0].vote === "3")
            design3 = parseInt(response.data.result[0].count);
          if (response.data.result[0].vote === "4")
            design4 = parseInt(response.data.result[0].count);
        }
        if (response.data.result[1]) {
          if (response.data.result[1].vote === "1")
            design1 = parseInt(response.data.result[1].count);
          if (response.data.result[1].vote === "2")
            design2 = parseInt(response.data.result[1].count);
          if (response.data.result[1].vote === "3")
            design3 = parseInt(response.data.result[1].count);
          if (response.data.result[1].vote === "4")
            design4 = parseInt(response.data.result[1].count);
        }
        if (response.data.result[2]) {
          if (response.data.result[2].vote === "1")
            design1 = parseInt(response.data.result[2].count);
          if (response.data.result[2].vote === "2")
            design2 = parseInt(response.data.result[2].count);
          if (response.data.result[2].vote === "3")
            design3 = parseInt(response.data.result[2].count);
          if (response.data.result[2].vote === "4")
            design4 = parseInt(response.data.result[2].count);
        }
        if (response.data.result[3]) {
          if (response.data.result[3].vote === "1")
            design1 = parseInt(response.data.result[3].count);
          if (response.data.result[3].vote === "2")
            design2 = parseInt(response.data.result[3].count);
          if (response.data.result[3].vote === "3")
            design3 = parseInt(response.data.result[3].count);
          if (response.data.result[3].vote === "4")
            design4 = parseInt(response.data.result[3].count);
        }
        let total = design1 + design2 + design3 + design4;
        this.setState((state, props) => ({
          ...state,
          voteResult: {
            design1,
            design2,
            design3,
            design4,
            total,
          },
        }));
      });
  };

  componentDidMount() {
    this.getResults();
  }

  render() {
    return (
      <div className="mainDiv">
        <Container>
          <Row>
            <Col>
              <VoteHeader />
            </Col>
          </Row>
          <Row>
            <Col
              lg={6}
              md={12}
              onClick={() => {
                this.changeVote(1);
              }}
            >
              <VoteOption
                active={this.state.vote === 1 ? true : false}
                code={this.state.code}
                changeCode={this.changeCode}
                className="preview-top"
                makeVote={this.makeVote}
                design={1}
                voted={this.state.voted}
                result={this.state.voteResult.design1}
                resultTotal={this.state.voteResult.total}
                title={"Designvorschlag 1"}
                text={
                  "Battenburg-Design in Neongelb/Grün, Konturmarkierung gestrichelt Lime, Stadtmusikanten, Amtliches/hoheitliches CD an der Tür, Leistungserbringer"
                }
                bild1="img/fw_hb_11.jpg"
                bild2="img/fw_hb_12.jpg"
              />
            </Col>
            <Col
              lg={6}
              md={12}
              onClick={() => {
                this.changeVote(2);
              }}
            >
              <VoteOption
                active={this.state.vote === 2 ? true : false}
                code={this.state.code}
                changeCode={this.changeCode}
                makeVote={this.makeVote}
                design={2}
                voted={this.state.voted}
                result={this.state.voteResult.design2}
                resultTotal={this.state.voteResult.total}
                title={"Designvorschlag 2"}
                text={
                  "Battenburg-Design in Neongelb/Rot, Konturmarkierung gestrichelt Lime, Stadtmusikanten, Amtliches/hoheitliches CD an der Tür, Leistungserbringer"
                }
                bild1="img/fw_hb_21.jpg"
                bild2="img/fw_hb_22.jpg"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "50px" }}>
            <Col
              lg={6}
              md={12}
              onClick={() => {
                this.changeVote(3);
              }}
            >
              <VoteOption
                active={this.state.vote === 3 ? true : false}
                code={this.state.code}
                changeCode={this.changeCode}
                makeVote={this.makeVote}
                design={3}
                voted={this.state.voted}
                result={this.state.voteResult.design3}
                resultTotal={this.state.voteResult.total}
                title={"Designvorschlag 3"}
                text={
                  "Stadtmusikanten mit roten Schwung, Konturmarkierung gestrichelt Lime, Amtliches/hoheitliches CD an der Tür, Leistungserbringer"
                }
                bild1="img/fw_hb_31.jpg"
                bild2="img/fw_hb_32.jpg"
              />
            </Col>
            <Col
              lg={6}
              md={12}
              onClick={() => {
                this.changeVote(4);
              }}
            ></Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "center" }}>
              <a
                href="https://moinmoin-media.de/impressum.html"
                target="_blank"
              >
                Impressum
              </a>{" "}
              |{" "}
              <a
                href="https://moinmoin-media.de/datenschutz.html"
                target="_blank"
              >
                Datenschutz
              </a>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "center", marginTop: "10px" }}>
              Die Abstimmung wird bereitgestellt von{" "}
              <a href="https://www.moinmoin-media.de" target="_blank">
                MOIN MOIN media
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Vote;
